import React, { Component } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Introduction from "../components/introduction"
import Spacer from "../components/spacer"
import CenteredSlider from "../components/centered-slider"
import CenteredSlide from "../components/centered-slide"

class Success extends Component {
  render() {
    const data = this.props.data

    return (
      <Layout background={ true } blog={ false }>
        <SEO title="Thank you" />
        <Introduction 
          title="Thank you"
          subtitle={<><p>Thank you for getting in touch. We'll get back to you as soon as possible.</p></>}
          link="/work"
        />
        <Spacer space="4" />
        <CenteredSlider>
          {data.allWordpressPost.edges.map(({ node }) => (
              <CenteredSlide key={node.id} title={node.title} image={ node.featured_media.localFile.childImageSharp.original.src } link={node.path} />
          ))}
        </CenteredSlider> 
        <Spacer space="4" />
      </Layout>
    )
  }
}

export default Success

export const pageQuery = graphql`
  query {
    allWordpressPost(sort: {fields: [date], order: DESC}) {
      edges {
        node {
          id
          slug
          path
          title
          featured_media {
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }        
        }
      }
    }
  }
`